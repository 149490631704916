import React from "react";
import './App.css';
import TempApp from './components/TempApp';

function App() {
  return (
    <TempApp />

  );
}

export default App;
